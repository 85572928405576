
import { computed, defineComponent, onMounted, ref } from "vue";
import { LoadEntity } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import PaymentService from "@/core/services/PaymentService";
import { setCurrentPageBreadcrumbsWithParams } from "@/core/helpers/breadcrumb";
import { onUpdated } from "@vue/runtime-core";
import PaymentFormEdit from "@/views/payment/PaymentFormEdit.vue";
import { ModelAddress } from '@/core/entity/IAddress'

export default defineComponent({
  name: "Update",
  components: {PaymentFormEdit},
  props: {
    paymentId: {type: String}
  },
  setup(props) {
    const invoice = computed(() => store.state.InvoiceModule.invoice)
    const payment = ref<any>({});

    const loadPayment = async (id) => {
      store.dispatch(Actions.SET_RECENT_PAYMENT, id);
      return await PaymentService.get(id);
    }


    onMounted(async () => {
      payment.value = await loadPayment(props.paymentId);
      setCurrentPageBreadcrumbsWithParams('Edit Payment', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Edit Payment'}
      ])
    })

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Edit Payment', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: 'View Invoice'},
        {link: false, router: '', text: 'Edit Payment'}
      ])
    })
    return {
      ...LoadEntity(Actions.LOAD_INVOICE),
      invoice,
      payment,
      ModelAddress,
    }
  }
})
