
import {computed, defineComponent, ref} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {ILocation} from "@/core/entity/ILocation";
import store from "@/store";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Row from "@/components/base/common/Row.vue";
import {HandleState} from "@/core/composite/composite";
import {PAYMENT_METHOD, PAYMENT_STATUS, PAYMENT_STATUS_PENDING, PAYMENT_SUB_TYPE} from "@/core/config/Constant";
import {Field} from "vee-validate";
import PaymentService from "@/core/services/PaymentService";
import router from "@/router";
import ExchangeRateService from "@/core/services/ExchangeRateService";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";

export default defineComponent({
  name: "PaymentFormEdit",
  components: {DateTimeSelect, Row, CurrencyFormat, BaseSelect, BaseRadio, BaseForm, Field},
  props: {
    payment: {type: Object, required: true},
    paymentModel: {type: Object,},
    invoice: {type: Object, required: true}
  },
  setup(props) {
    const sanctionsCheck = ref(true);

    const invoiceRef = ref(props.invoice);
    const submitting = ref(false);
    const paidAmount = ref(0);
    const unPaid = ref(0);
    const model = ref<any>(props.paymentModel);
    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const currencies = computed(() => store.state.ExchangeRateModule.currencies);
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const loadSanctionsCheck = (id) => {
      PaymentService.sanctionsCheck(id).then(res => {
        sanctionsCheck.value = res.results;
      })
    }
    loadSanctionsCheck(props.payment.id)
    const calculateAmountInvCurrency = async () => {
      submitting.value = true;
      if (model.value.currency === invoiceRef.value.currency) {
        model.value.amountInvCurrency = model.value.amount;
      } else {
        const exchanged = await ExchangeRateService.exchangeRate(model.value.currency, invoiceRef.value.currency, model.value.amount);
        model.value.amountInvCurrency = exchanged.converted;
      }
      submitting.value = false
      calculatePaid();
    }

    const calculateAmount = async () => {
      if (model.value.amount > 0) return;
      submitting.value = true;
      if (model.value.currency === invoiceRef.value.currency) {
        model.value.amount = model.value.amountInvCurrency;
      } else {
        const exchanged = await ExchangeRateService.exchangeRate(invoiceRef.value.currency, model.value.currency, model.value.amountInvCurrency);
        model.value.amount = exchanged.converted;
      }
      submitting.value = false
      calculatePaid();
    }

    const calculatePaid = () => {
      const paid = props.invoice.paid - props.payment.amount + Number(model?.value.amount)
      paidAmount.value = paid;
      unPaid.value = props.invoice.payable - paid;
    };
    calculatePaid();
    const submit = () => {
      submitting.value = true
      PaymentService.update(props.payment.id, model.value).then(() => {
        router.push({
          name: "invoicePayments",
          params: {id: props.invoice.id}
        });
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      model,
      countries,
      states,
      paidAmount,
      unPaid,
      ...HandleState(),
      calculatePaid,
      submitting,
      PAYMENT_SUB_TYPE,
      PAYMENT_STATUS,
      PAYMENT_METHOD,
      submit,
      calculateAmountInvCurrency,
      currencies,
      calculateAmount,
      sanctionsCheck,
      PAYMENT_STATUS_PENDING,
    }
  },
  methods: {

    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name && this?.model && this?.model?.address) this.model.address.stateName = name
    },
    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name && this?.model && this?.model?.address) this.model.address.countryName = name
    },
    onCancel() {
      this.$router.go(-1)
    },
  }
})
