import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 0,
  class: "card-body pb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentFormEdit = _resolveComponent("PaymentFormEdit")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, "Invoice: " + _toDisplayString(_ctx.invoice?.number) + " Edit Payment", 1)
    ]),
    (_ctx.invoice.id && _ctx.payment.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_PaymentFormEdit, {
            invoice: _ctx.invoice,
            payment: _ctx.payment,
            "payment-model": {
          type: _ctx.payment.type,
          status: _ctx.payment.status,
          subType: _ctx.payment.subType,
          name: _ctx.payment.name,
          address: _ctx.payment.address ? _ctx.payment.address : new _ctx.ModelAddress(),
          amount :_ctx.payment.amount,
          currency: _ctx.payment.currency,
          method: _ctx.payment.method ? _ctx.payment.method : 'NA',
          amountInvCurrency: _ctx.payment.amountInvCurrency,
          number: _ctx.payment.number,
          paidDt: _ctx.payment.paidDt
        }
          }, null, 8, ["invoice", "payment", "payment-model"])
        ]))
      : _createCommentVNode("", true)
  ]))
}